import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface UnitGroupProps {
  unitArr: any[];
}
export default function UnitGroup(props: UnitGroupProps) {
  const { unitArr } = props;
  return (
    <UnitGroupWrapper>
      {unitArr.map((unit: any, index) => (
        <div key={"unit-" + index} className="unit-item">
          <h3 className="typography body-small-regular color-text-secondary">
            {unit.title}
          </h3>
          <p className="typography headings-small color-text-primary m-0">
            {unit.amount + " " + unit.unit}
            <i className="fa fa-circle" aria-hidden="true"></i>
          </p>
        </div>
      ))}
    </UnitGroupWrapper>
  );
}

const UnitGroupWrapper = styled.div`
  display: flex;
  .unit-item {
    padding: 12px 16px;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #e6ebe7;
    h3 {
    }
    p i {
      font-size: 9px;
      line-height: 20px;
      color: #6a9874;
      padding-left: 4px;
    }

    margin-right: 8px;
  }
`;
