import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Avatar from "../Avatar";
import { useDispatch, useSelector } from "react-redux";
import { checkCloseMessage } from "./../../action/api";
interface CardProps {
  content: any;
}
export default function Card(props: CardProps) {
  const dispatch = useDispatch();
  const { content } = props;
  const getMonthDay = (inputDateString: string) => {
    var dateObject = new Date(inputDateString);
    var month = dateObject.toLocaleString("default", { month: "short" });
    var day = dateObject.getDate();
    var formattedDate = month + " " + day;
    return formattedDate;
  };
  const getTime = (inputDateString: string) => {
    const date = new Date(inputDateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // If hours is 0, display 12
    const formattedTime = `${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")}${ampm}`;
    return formattedTime;
  };
  const closeFunc = () => {
    checkCloseMessage(
      content.message_type == "New Appointment" ? 1 : 2,
      Number(content.entry_id)
    )(dispatch);
  };
  return (
    <CardWrapper className="card-wrapper">
      <div className=" card mb-3 p-4">
        <div className="card-header bg-transparent modal-header">
          <h5 className="modal-title typography headings-xsmall color-text-secondary">
            <i className="fa fa-regular fa-calendar"></i>&nbsp;{" "}
            {content.message_type}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="card"
            aria-label="Close"
            onClick={() => {
              closeFunc();
            }}
          ></button>
        </div>
        <div className="card-body px-1 pb-0">
          <div style={{ position: "relative" }}>
            <div className="card-body-title">
              <Avatar imgSrc={content.image !== "" ? content.image : ""} />
              &nbsp;
              <h3 className="typography headings-small color-text-primary mt-auto">
                {content.author}
              </h3>
            </div>
            <p className="typography body-small-regular mt-2 mb-2">
              {content.message}
            </p>
            {content.url_link != "" && (
              <a className="btn btnn-secondary button" href={content.url_link}>
                {content.url_text} &nbsp;{" "}
                <i className="fa fa-solid fa-arrow-right"></i>
              </a>
            )}
          </div>
          <div className="card-op">
            <div>
              <p className="button btn-small m-0 color-text-primary p-0">
                {getMonthDay(content.created)}
              </p>
              <p className="m-0">{getTime(content.created)}</p>
            </div>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  height: 100%;
  .card-body p {
    padding-bottom: 30px;
  }
  .card {
    max-width: 411px;
    margin-right: 17px;
    @media (max-width: 576px) {
      margin-right: 0px;
    }
  }
  .card-body {
    display: flex;
    .card-body-title {
      display: flex;
    }
    justify-content: space-between;
    a.btnn-secondary {
      position: absolute;
      bottom: 0px;
    }
  }
  .card-op div {
    background: #f4f0ee;
    border-radius: 4px;
    padding: 12px;
    min-width: 75px;
    margin-left: 20px;
  }
  .card-op .button {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    padding: 0px;
  }
  .card-op p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-align: left;
  }
`;
