import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Button from "./../../components/Button";
import Navbar from "./../../components/Navbar";
import Sliders from "./../../components/Slider";
import Progressbar from "../../components/Progressbar";
import targetIcon from "./../../assets/excercise-target.svg";
import Accordians from "./../../components/Accordian";
import "zingchart/es6";
// @ts-ignore
import ZingChart from "zingchart-react";
// EXPLICITLY IMPORT MODULE
import "zingchart/modules-es6/zingchart-depth.min.js";
import TargetCard from "./TargetCard";

interface TargetDetailProps {
  target: any;
  activityPlan: any;
  videosMore: any;
  dropdowns: any;
  careTeamNotes: any;
  ismobile: any;
}
export default function TargetDetail(props: TargetDetailProps) {
  const {
    target,
    activityPlan,
    ismobile,
    videosMore,
    dropdowns,
    careTeamNotes,
  } = props;
  const tempActivity = [
    { id: "Nutrition Target", index: 1 },
    { id: "Behavior Target", index: 2 },
    { id: "Fitness Target", index: 3 },
  ];
  const myData = [
    {
      type: "gauge",
      globals: {
        fontSize: 15,
      },
      plotarea: {
        margin: "10px",
        padding: 0,
        height: "100%",
        width: "100%",
      },
      plot: {
        size: "100%",
        valueBox: {
          placement: "center",
          text: "%v", //default
          fontSize: 35,
          rules: [
            {
              rule: "%v >= 0 && %v >= 0",
              text: "%v",
            },
          ],
        },
      },
      tooltip: {
        borderRadius: 5,
      },

      scaleR: {
        aperture: 180,
        minValue: 0, //0
        maxValue: 120, //120
        step: 30,
        center: {
          visible: false,
        },
        tick: {
          visible: false,
        },
        item: {
          offsetR: -90,
          rules: [
            {
              rule: "%i == 9",
              offsetX: 15,
            },
          ],
        },
        labels: ["0", "30", "60", "90", "120"],
        ring: {
          size: 50,
          rules: [
            {
              rule: "%v < 30",
              backgroundColor: "#DBCC5C",
            },
            {
              rule: "%v >= 30 && %v < 90",
              backgroundColor: "#6A9874",
            },
            {
              rule: "%v >= 90",
              backgroundColor: "#DBCC5C",
            },
          ],
        },
      },
      series: [
        {
          values: [Number(target?.chart_ep_metric) || 0], // starting value
          backgroundColor: "black",
          indicator: [5, 2, 1, 1, 0.25],
          size: "85%",
          animation: {
            effect: 2,
            method: 1,
            sequence: 4,
            speed: 900,
          },
        },
      ],
    },
    {
      type: "gauge",
      globals: {
        fontSize: 15,
      },
      plotarea: {
        margin: "10px",
        padding: 0,
        height: "100%",
        width: "100%",
      },
      plot: {
        size: "100%",
        valueBox: {
          placement: "center",
          text: "%v", //default
          fontSize: 35,
          rules: [
            {
              rule: "%v >= 0 && %v >= 0",
              text: "%v",
            },
          ],
        },
      },
      tooltip: {
        borderRadius: 5,
      },
      scaleR: {
        aperture: 180,
        minValue: 0, //0
        maxValue: 120, //120
        step: 30,
        center: {
          visible: false,
        },
        tick: {
          visible: false,
        },
        item: {
          offsetR: -90,
          rules: [
            {
              rule: "%i == 9",
              offsetX: 15,
            },
          ],
        },
        labels: ["0", "30", "60", "90", "120"],
        ring: {
          size: 50,
          rules: [
            {
              rule: "%v < 30",
              backgroundColor: "#DBCC5C",
            },
            {
              rule: "%v >= 30 && %v < 90",
              backgroundColor: "#6A9874",
            },
            {
              rule: "%v >= 90",
              backgroundColor: "#DBCC5C",
            },
          ],
        },
      },
      series: [
        {
          values: [Number(target?.chart_rd_metric) || 0], // starting value
          backgroundColor: "black",
          indicator: [5, 2, 1, 1, 0.25],
          size: "85%",
          animation: {
            effect: 2,
            method: 1,
            sequence: 4,
            speed: 900,
          },
        },
      ],
    },
    {
      type: "gauge",
      globals: {
        fontSize: 15,
      },
      plotarea: {
        margin: "10px",
        padding: 0,
        height: "100%",
        width: "100%",
      },
      plot: {
        size: "100%",
        valueBox: {
          placement: "center",
          text: "%v", //default
          fontSize: 35,
          rules: [
            {
              rule: "%v >= 0 && %v >= 0",
              text: "%v",
            },
          ],
        },
      },
      tooltip: {
        borderRadius: 5,
      },
      scaleR: {
        aperture: 180,
        minValue: 0, //0
        maxValue: 120, //120
        step: 30,
        center: {
          visible: false,
        },
        tick: {
          visible: false,
        },
        item: {
          offsetR: -90,
          rules: [
            {
              rule: "%i == 9",
              offsetX: 15,
            },
          ],
        },
        labels: ["0", "30", "60", "90", "120"],
        ring: {
          size: 50,
          rules: [
            {
              rule: "%v < 30",
              backgroundColor: "#DBCC5C",
            },
            {
              rule: "%v >= 30 && %v < 90",
              backgroundColor: "#6A9874",
            },
            {
              rule: "%v >= 90",
              backgroundColor: "#DBCC5C",
            },
          ],
        },
      },
      series: [
        {
          values: [Number(target?.chart_hp_metric) || 0], // starting value
          backgroundColor: "black",
          indicator: [5, 2, 1, 1, 0.25],
          size: "85%",
          animation: {
            effect: 2,
            method: 1,
            sequence: 4,
            speed: 900,
          },
        },
      ],
    },
  ];
  const [items, setItems] = useState([
    {
      head_title: target?.chart_ep_label || "Fitness" + " Target",
      head_description: target?.chart_ep_target_description,
      period: "Weekly",
      iconSrc: "https://a360h.com/assets/images/excercise-target.svg",
      time_value: 100,
      time_description:
        "Increasing your heart rate to 150 bpm for 120 minutes a week contributes to overall heart rate stability and health.",
      updated_date:
        target?.ep_last_updated || "Last updated Aug 8 at 2:57 PM ET",
      activity_plan: [
        {
          title: "Mini-circuit training session at lunchtime",
        },
      ],
      myData: [
        {
          type: "gauge",
          globals: {
            fontSize: 15,
          },
          plotarea: {
            margin: "10px",
            padding: 0,
            height: "100%",
            width: "100%",
          },
          plot: {
            size: "100%",
            valueBox: {
              placement: "center",
              text: "%v", //default
              fontSize: 35,
              rules: [
                {
                  rule: "%v >= 0 && %v >= 0",
                  text: "%v",
                },
              ],
            },
          },
          tooltip: {
            borderRadius: 5,
          },

          scaleR: {
            aperture: 180,
            minValue: 0, //0
            maxValue: 150, //120
            step: 50,
            center: {
              visible: false,
            },
            tick: {
              visible: false,
            },
            item: {
              offsetR: -90,
              rules: [
                {
                  rule: "%i == 9",
                  offsetX: 15,
                },
              ],
            },
            labels: ["0", "50", "100", "150"],
            ring: {
              size: 50,
              rules: [
                {
                  rule: "%v < 50",
                  backgroundColor: "#DBCC5C",
                },
                {
                  rule: "%v >= 50 && %v < 100",
                  backgroundColor: "#6A9874",
                },
                {
                  rule: "%v >= 100",
                  backgroundColor: "#DBCC5C",
                },
              ],
            },
          },
          series: [
            {
              values: [Number(target?.chart_ep_metric) || 0], // starting value
              backgroundColor: "black",
              indicator: [5, 2, 1, 1, 0.25],
              size: "85%",
              animation: {
                effect: 2,
                method: 1,
                sequence: 4,
                speed: 900,
              },
            },
          ],
        },
        {
          type: "gauge",
          globals: {
            fontSize: 15,
          },
          plotarea: {
            margin: "10px",
            padding: 0,
            height: "100%",
            width: "100%",
          },
          plot: {
            size: "100%",
            valueBox: {
              placement: "center",
              text: "%v", //default
              fontSize: 35,
              rules: [
                {
                  rule: "%v >= 0 && %v >= 0",
                  text: "%v",
                },
              ],
            },
          },
          tooltip: {
            borderRadius: 5,
          },
          scaleR: {
            aperture: 180,
            minValue: 0, //0
            maxValue: 30, //120
            step: 10,
            center: {
              visible: false,
            },
            tick: {
              visible: false,
            },
            item: {
              offsetR: -90,
              rules: [
                {
                  rule: "%i == 9",
                  offsetX: 15,
                },
              ],
            },
            labels: ["0", "10", "20", "30"],
            ring: {
              size: 50,
              rules: [
                {
                  rule: "%v < 10",
                  backgroundColor: "#DBCC5C",
                },
                {
                  rule: "%v >= 10 && %v < 20",
                  backgroundColor: "#6A9874",
                },
                {
                  rule: "%v >= 20",
                  backgroundColor: "#DBCC5C",
                },
              ],
            },
          },
          series: [
            {
              values: [Number(target?.chart_rd_metric) || 0], // starting value
              backgroundColor: "black",
              indicator: [5, 2, 1, 1, 0.25],
              size: "85%",
              animation: {
                effect: 2,
                method: 1,
                sequence: 4,
                speed: 900,
              },
            },
          ],
        },
        {
          type: "gauge",
          globals: {
            fontSize: 15,
          },
          plotarea: {
            margin: "10px",
            padding: 0,
            height: "100%",
            width: "100%",
          },
          plot: {
            size: "100%",
            valueBox: {
              placement: "center",
              text: "%v", //default
              fontSize: 35,
              rules: [
                {
                  rule: "%v >= 0 && %v >= 0",
                  text: "%v",
                },
              ],
            },
          },
          tooltip: {
            borderRadius: 5,
          },
          scaleR: {
            aperture: 180,
            minValue: 0, //0
            maxValue: 3, //120
            step: 1,
            center: {
              visible: false,
            },
            tick: {
              visible: false,
            },
            item: {
              offsetR: -90,
              rules: [
                {
                  rule: "%i == 9",
                  offsetX: 15,
                },
              ],
            },
            labels: ["0", "1", "2", "3"],
            ring: {
              size: 50,
              rules: [
                {
                  rule: "%v < 1",
                  backgroundColor: "#DBCC5C",
                },
                {
                  rule: "%v >= 1 && %v < 2",
                  backgroundColor: "#6A9874",
                },
                {
                  rule: "%v >= 2",
                  backgroundColor: "#DBCC5C",
                },
              ],
            },
          },
          series: [
            {
              values: [Number(target?.chart_hp_metric) || 0], // starting value
              backgroundColor: "black",
              indicator: [5, 2, 1, 1, 0.25],
              size: "85%",
              animation: {
                effect: 2,
                method: 1,
                sequence: 4,
                speed: 900,
              },
            },
          ],
        },
      ],
    },
    {
      head_title: target?.chart_rd_label || "Nutrition" + " Target",
      head_description: target?.chart_rd_target_description,
      period: "Daily",
      iconSrc: "https://a360h.com/assets/images/nutrition_target_icon.svg",
      time_value: 60,
      time_description:
        "Having an optimal intake of fiber per day will help improve your ability to absorb nutrients, which contributes to your overall health goal.",
      updated_date:
        target?.rd_last_updated || "Last updated Aug 8 at 2:57 PM ET",
      activity_plan: [
        {
          title: "Mini-circuit training session at lunchtime",
        },
      ],
    },
    {
      head_title: target?.chart_hp_label || "Behavior" + " Target",
      head_description: target?.chart_hp_target_description,
      period: "Weekly",
      iconSrc: "https://a360h.com/assets/images/behavior_target_icon.svg",
      time_value: 12,
      time_description:
        "Increasing your heart rate to 150 bpm for 120 minutes a week contributes to overall heart rate stability and health.",
      updated_date:
        target?.hp_last_updated || "Last updated Aug 8 at 2:57 PM ET",
      edit_activity_log: true,
      activity_plan: [
        {
          title: "Mini-circuit training session at lunchtime",
        },
      ],
    },
  ]);
  useEffect(() => {
    const temp_items = [...items];
    if (target && activityPlan) {
      for (let i = 0; i < temp_items.length; i++) {
        const filteredArr = tempActivity.filter((sub: any) => {
          return (
            sub.id == items[i].head_title ||
            sub.id.split(" ")[0] == items[i].head_title
          );
        });
        const filteredResult =
          activityPlan.length > 0 &&
          filteredArr.length > 0 &&
          activityPlan.filter((sub: any) => sub.id == filteredArr[0].index);
        temp_items[i].activity_plan =
          filteredResult.length > 0 ? filteredResult[0].data : [];
      }
      setItems([...temp_items]);
    }
  }, [target, activityPlan]);
  return (
    <TargetDetailWrapper className="container">
      <p className="typography body-medium-regular"> Target Detail</p>
      {items.map((item, index) => (
        <TargetCard
          item={item}
          key={"target-card-" + index}
          index={index}
          charts={items[0].myData}
          videosMore={videosMore}
          careTeamNotes={careTeamNotes}
          dropdowns={dropdowns}
          ismobile={ismobile}
        />
      ))}
    </TargetDetailWrapper>
  );
}

const TargetDetailWrapper = styled.div`
  padding-bottom: 32px;
  .target-overview-cards {
    margin-bottom: 30px;
  }
`;
