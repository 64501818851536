import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "./../../components/Button";
import Navbar from "./../../components/Navbar";
import Sliders from "./../../components/Slider";
import TargetOverview from "./TargetOverview";
import TargetDetail from "./TargetDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  receiveTargetOverview,
  receiveDashbaordHeader,
  receiveActivityPlan,
  receiveDashboardMessages,
  receiveDropdowns,
  receiveVideoMore,
  receiveCareTeamNotes,
} from "./../../action/api";

export default function Home() {
  const state = useSelector((state: any) => state.apiReducer);
  console.log("state", state);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Check the screen width and set isMobile accordingly
      setIsMobile(window.innerWidth <= 767);
    };

    // Add a resize event listener
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    receiveDashboardMessages()(dispatch);
    receiveDashbaordHeader()(dispatch);
    receiveTargetOverview()(dispatch);
    for (let i = 0; i < 3; i++) {
      receiveActivityPlan(i + 1)(dispatch);
      receiveVideoMore(i + 27)(dispatch);
      receiveDropdowns(i + 13)(dispatch);
      receiveCareTeamNotes(i + 1)(dispatch);
    }
  }, [dispatch]);

  return (
    <HomeWrapper>
      {/* <Navbar /> */}
      <HeaderSliderWrapper className="home-slider-wrapper pt-5 pb-5 position-relative">
        <div className="header-slider-title  container">
          <div>
            <h3
              className={`color-pure-white typography body-medium-regular m-0 `}
            >
              {state.dashboard_header ? state.dashboard_header[0].greeting : ""}
            </h3>
            <h1
              className={`color-pure-white typography m-0 ${
                isMobile ? " headings-xxlarge mb-4 mt-1" : " display-large"
              }`}
            >
              {state.dashboard_header
                ? state.dashboard_header[0].first_name
                : ""}
            </h1>
          </div>
          <div className="card-op">
            <h3 className="color-pure-white typography body-medium-regular m-0">
              My Goal
            </h3>
            <h1
              className={`color-pure-white typography m-0 ${
                isMobile ? " headings-large  mt-1 " : " headings-xlarge"
              }`}
            >
              {state.dashboard_header ? state.dashboard_header[0].goal : ""}
            </h1>
          </div>
        </div>
        <p className="color-pure-white typography body-medium-regular container mt-2">
          {state.dashboard_messages.length == 1
            ? "1 New Message or Notification"
            : state.dashboard_messages.length +
              " New Messages and Notifications"}
        </p>
        {state.dashboard_messages && (
          <Sliders slides={state.dashboard_messages} />
        )}
      </HeaderSliderWrapper>
      <TargetOverview
        targetOverview={state.target_overview ? state.target_overview[0] : {}}
        ismobile={isMobile ? true : undefined}
      />
      {state.target_overview && state.activity_plan && (
        <TargetDetail
          target={state.target_overview ? state.target_overview[0] : {}}
          ismobile={isMobile ? true : undefined}
          videosMore={state.videos_more}
          dropdowns={state.dropdowns}
          careTeamNotes={state.care_teamnotes}
          activityPlan={state.activity_plan}
        />
      )}
    </HomeWrapper>
  );
}

const HomeWrapper = styled.div`
  // .btn-xxx {
  //   margin: 10px;
  // }
  background: #f1f6f2;
`;
const HeaderSliderWrapper = styled.div`
  .header-slider-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card-op {
      padding: 15px 16px;
      gap: 4px;
      border-radius: 12px;
      background: #1d1f1d1a;
    }
  }
`;
