import {
  GET_ACTIVITY_LOG,
  ADD_ACTIVITY_LOG,
  EDIT_ACTIVITY_LOG,
  DELETE_ACTIVITY_LOG,
} from "../action/type";

const initialState: any = [];

function activityLogReducer(activityLogs = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case ADD_ACTIVITY_LOG:
      return [...activityLogs, payload];
    case GET_ACTIVITY_LOG:
      if (!activityLogs[payload.id])
        return { ...activityLogs, [payload.id]: payload.data };
      else return { ...activityLogs };
    case EDIT_ACTIVITY_LOG:
      return activityLogs.map((activityLog: any) => {
        if (activityLog.id === payload.id) {
          return {
            ...activityLog,
            ...payload,
          };
        } else {
          return activityLog;
        }
      });
    // case DELETE_ACTIVITY_LOG:
    //   return activityLogs.filter(
    //     (activityLog: any) => activityLog.id !== payload.id
    //   );
    case DELETE_ACTIVITY_LOG:
      return activityLogs.map((activityLog: any) => {
        if (activityLog.id === payload.id) {
          return {
            ...activityLog,
            disable: true,
          };
        } else {
          return activityLog;
        }
      });
    default:
      return activityLogs;
  }
}

export default activityLogReducer;
