import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "./../Card";
import List from "./../List";

interface AccordiansProps {
  accordian: any;
}
export default function Accordians(props: AccordiansProps) {
  const { accordian } = props;
  return (
    <AccordiansWrapper>
      <Accordion defaultActiveKey="0">
        {accordian.map((subAccordian: any, subAccordianIndex: any) => (
          <Accordion.Item
            eventKey={subAccordianIndex}
            key={"Accordion.Item - " + subAccordianIndex}
          >
            <Accordion.Header className="color-text-primary typography headings-small">
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>
              {subAccordian.plan_item}
            </Accordion.Header>
            <Accordion.Body></Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </AccordiansWrapper>
  );
}

const AccordiansWrapper = styled.div`
  .accordion {
    .accordion-item {
      border: 0px;
      border-bottom: 1px solid #f4f4f4;
      .accordion-header .accordion-button {
        padding-left: 0px;
        padding-right: 0px;
        color: #1d1f1d;
        font-weight: 600;
        i {
          color: #808782;
          opacity: 0.75;
          margin-right: 8px;
        }
        &:focus {
          border: none;
          box-shadow: none;
        }
        &:not(.collapsed) {
          i {
            color: #6a9874e5;
            opacity: 1;
          }
          background-color: transparent;
          box-shadow: none;
        }
      }
      .accordion-body {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        .accordian-sub-title-desc {
          padding-left: 20px;
          i {
            margin-right: 8px;
            width: 11px;
          }
          margin-bottom: 4px;
        }
        .button-group-accordian {
          margin-top: 12px;
          margin-bottom: 16px;
          display: flex;
          button.btn {
            width: 98%;
            margin-right: 5px;
          }
        }
      }
    }
  }
`;
