import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Avatar1 from "./../../assets/Avatar.png";

interface AvatarProps {
  imgSrc: any;
}
export default function Avatar(props: AvatarProps) {
  const { imgSrc } = props;
  return (
    <AvatarWrapper>
      <img
        src={
          imgSrc
            ? `https://a360h.com/assets/images/headshots/${imgSrc}`
            : Avatar1
        }
      />
    </AvatarWrapper>
  );
}

const AvatarWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-raidus: 50%;
  img {
    width: 100%;
    border-radius: 100%;
  }
`;
