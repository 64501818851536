import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import UnitGroup from "../UnitGroup";
import HealthAccordianItem from "./HealthAccordianItem";

interface HealthAccordianProps {
  accordian: any;
}
export default function HealthAccordian(props: HealthAccordianProps) {
  const { accordian } = props;
  return (
    <HealthAccordianWrapper>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="color-text-primary typography headings-small">
            <div className="health-accordion-header">
              <h2>{accordian[0].title}</h2>
              <UnitGroup unitArr={accordian[0].unitInfo} />
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {accordian[0].unitInfo.map((item: any, index: any) => (
              <HealthAccordianItem
                key={"health-accordian-item-" + index}
                item={item}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </HealthAccordianWrapper>
  );
}

const HealthAccordianWrapper = styled.div`
  .accordion {
    .accordion-item {
      padding: 24px;
      box-shadow: 0px 2px 8px 0px #0000000d;
      background-color: white;
      border-radius: 12px;
      border: 0px;
      .health-accordion-header {
      }
      h2 {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: left;
        margin-bottom: 16px;
      }
      border: 0px;
      border-bottom: 1px solid #f4f4f4;
      .accordion-header .accordion-button {
        padding-left: 0px;
        padding-right: 0px;
        color: #1d1f1d;
        font-weight: 600;
        i {
          color: #808782;
          opacity: 0.75;
          margin-right: 8px;
        }
        &:focus {
          border: none;
          box-shadow: none;
        }
        &:not(.collapsed) {
          i {
            color: #6a9874e5;
            opacity: 1;
          }
          background-color: transparent;
          box-shadow: none;
        }
      }
      .accordion-body {
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        .accordian-sub-title-desc {
          padding-left: 20px;
          i {
            margin-right: 8px;
            width: 11px;
          }
          margin-bottom: 4px;
        }
        .button-group-accordian {
          margin-top: 12px;
          margin-bottom: 16px;
          display: flex;
          button.btn {
            width: 98%;
            margin-right: 5px;
          }
        }
      }
    }
    .card-gray {
      .card-header {
        line-height: 33px;
        div {
          margin-right: 8px;
        }
        margin-bottom: 8px;
      }
      background: #e6ebe745;
      padding: 16px;
      border-radius: 12px;
      gap: 8px;
      .card-header {
        display: flex;
      }
    }
    .video-more-list {
      background: #e6ebe745;
      padding: 16px;
      border-radius: 12px;
      gap: 8px;
    }
  }
`;
