import {
  GET_ACTIVITY_LOG,
  EDIT_ACTIVITY_LOG,
  DELETE_ACTIVITY_LOG,
  ADD_ACTIVITY_LOG,
} from "./type";
import ApiServices from "../services/apiServices";
import { Dispatch } from "redux";

export const receiveActivityLog = (id: any) => {
  return async (dispatch: Dispatch) => {
    const res = await ApiServices.getActivityLogs(id);
    console.log("getActivityLogs - > ", res);
    try {
      dispatch({
        type: GET_ACTIVITY_LOG,
        payload: {
          id: id == 1 ? "Nutrition" : id == 2 ? "Behavior" : "Fitness",
          data: res.data,
        },
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const AddActivityLog = (
  id: any,
  title: any,
  description: any,
  tags: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiServices.addActivityLog(id, {
        activity_id: title,
        date: tags[0],
        duration: tags[1],
        notes: description,
      });
      console.log("add-acivity-resposne", res);
      dispatch({
        type: ADD_ACTIVITY_LOG,
        payload: { id, title, description, tags },
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const EditActivityLog = (
  id: any,
  title: any,
  description: any,
  tags: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: EDIT_ACTIVITY_LOG,
        payload: { id, title, description, tags },
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const DeleteActivityLog = (
  id: any,
  title: any,
  description: any,
  tags: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: DELETE_ACTIVITY_LOG,
        payload: { id, title, description, tags },
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};
