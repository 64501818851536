import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "./../Card";
import List from "./../List";
import Avatar from "./../Avatar";
import UnitGroup from "../UnitGroup";
import AreaChart from "../Chart/AreaChart";
import Dropdown from "react-bootstrap/Dropdown";
import HealthList from "../List/HealthList";

interface HealthAccordianItemProps {
  item: any;
}
export default function HealthAccordianItem(props: HealthAccordianItemProps) {
  const { item } = props;
  return (
    <HealthAccordianItemWrapper>
      <hr />
      <HealthAccordianItemHeader>
        <div className="header-left-side">
          <div className="header-left-title">
            <h3 className="color-text-primary typography headings-xxlarge m-0">
              {item.title}
              <div className="typography headings-xlarge">
                {item.amount}{" "}
                <span className="typography body-small-regular color-text-secondary">
                  {item.unit}
                </span>
              </div>
            </h3>
            <p className="typography body-small-regular color-text-secondary m-0">
              Last Reading {item.lastUpdated}
            </p>
          </div>
          <div className="header-left-avatar typography body-small-regular color-text-secondary m-0">
            <img src={item.avatar} alt="" />
            Added by {item.name}
          </div>
        </div>
        <div className="header-right-side">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">Compare</Dropdown.Toggle>
            <Dropdown.Menu className="typography body-small-regular color-text-secondary">
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another action</Dropdown.Item>
              <Dropdown.Item>Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">Month</Dropdown.Toggle>
            <Dropdown.Menu className="typography body-small-regular">
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another action</Dropdown.Item>
              <Dropdown.Item>Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </HealthAccordianItemHeader>
      <HealthAccordianItemBody>
        <AreaChart />
        <div>
          <HealthList />
        </div>
      </HealthAccordianItemBody>
    </HealthAccordianItemWrapper>
  );
}

const HealthAccordianItemWrapper = styled.div`
  hr {
    borer: 2px solid #f4f4f4;
  }
`;
const HealthAccordianItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  .header-left-side {
    display: flex;
    .header-left-title {
      h3 {
        display: flex;
        div {
          padding-left: 8px;
          padding-top: 2px;
          line-height: 32px;
        }
      }
    }
    .header-left-avatar {
      display: flex;
      align-items: center;
      padding-left: 20px;
      img {
        margin-right: 8px;
      }
    }
  }
  .header-right-side {
    display: flex;
    .dropdown {
      button.dropdown-toggle {
        background: #f4f4f4;
        border-radius: 99px;
        border: 0px;
        padding: 8px 16px 8px 16px;
        color: #4e524f;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: center;
      }
      &:first-child {
        margin-right: 8px;
      }
    }
  }
`;
const HealthAccordianItemBody = styled.div``;
