import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Card from "./../Card";
import Slider from "react-slick";

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-arrow-circle-left" aria-hidden="true"></i>
    </div>
  );
}

interface SliderProps {
  slides: any[];
}
export default function Sliders(props: SliderProps) {
  const { slides } = props;
  useEffect(() => {
    let items = document.querySelectorAll(".carousel .carousel-item");

    items.forEach((el) => {
      const minPerSlide = 4;
      let next = el.nextElementSibling;
      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0];
        }
        let cloneChild = next.cloneNode(true) as Element;
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });
  }, []);
  var settings = {
    infinite: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          variableWidth: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          variableWidth: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          variableWidth: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          variableWidth: true,
        },
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };
  return (
    <SliderWrapper className="container pr-0">
      {slides && (
        <Slider {...settings} className="slider-item">
          {slides.map((slide: any, index: any) => (
            <Card key={"slide-" + index} content={slide} />
          ))}
        </Slider>
      )}
    </SliderWrapper>
  );
}

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  .card {
    border-radius: 12px;
    width: 411px;
    height: 100%;
    @media (max-width: 400px) {
      width: 100%;
    }
  }
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    height: inherit !important;
  }
  .slick-slide > div:first-child {
    height: 100%;
  }
`;
