import {
  GET_API_DASHBOARD_HEADER,
  GET_API_TARGET_OVERVIEW,
  GET_API_DASHBOARD_MESSAGES,
  GET_API_ACTIVITY_PLAN,
  GET_API_VIDEO_MORE,
  GET_API_DROPDOWN,
  GET_API_CARE_TEAM_NOTES,
  GET_API_CLOSE_MESSAGE,
} from "../action/type";

const initialState = {
  dashboard_header: null,
  target_overview: null,
  activity_plan: [],
  dashboard_messages: [],
  dropdowns: [],
  videos_more: [],
  care_teamnotes: [],
};

function apiReducer(apis = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case GET_API_DASHBOARD_HEADER:
      return {
        ...apis,
        dashboard_header: payload,
      };
    case GET_API_TARGET_OVERVIEW:
      return {
        ...apis,
        target_overview: payload,
      };
    case GET_API_DASHBOARD_MESSAGES:
      return {
        ...apis,
        dashboard_messages: [...payload],
      };
    case GET_API_CLOSE_MESSAGE:
      if (payload.data == 1) {
        const tempFilteredMsgs = apis.dashboard_messages.filter(
          (sub: any) => Number(sub.entry_id) != payload.id
        );
        return {
          ...apis,
          dashboard_messages: [...tempFilteredMsgs],
        };
      }
      return {
        ...apis,
      };
    case GET_API_DROPDOWN:
      if (!apis.dropdowns[payload.id])
        return {
          ...apis,
          dropdowns: { ...apis.dropdowns, [payload.id]: payload.data },
        };
      else return { ...apis };
    case GET_API_CARE_TEAM_NOTES:
      if (!apis.care_teamnotes[payload.id])
        return {
          ...apis,
          care_teamnotes: {
            ...apis.care_teamnotes,
            [payload.id]: payload.data,
          },
        };
      else return { ...apis };
    case GET_API_VIDEO_MORE:
      if (!apis.videos_more[payload.id])
        return {
          ...apis,
          videos_more: { ...apis.videos_more, [payload.id]: payload.data },
        };
      else return { ...apis };
    case GET_API_ACTIVITY_PLAN:
      const result = apis.activity_plan.filter(
        (api: any) => api.id == payload.id
      );
      if (result.length == 0)
        return {
          ...apis,
          activity_plan: [...apis.activity_plan, payload],
        };
      else return { ...apis };
    default:
      return apis;
  }
}

export default apiReducer;
