export const GET_API_DASHBOARD_HEADER = "GET_API_DASHBOARD_HEADER";
export const GET_API_TARGET_OVERVIEW = "GET_API_TARGET_OVERVIEW";
export const GET_API_ACTIVITY_PLAN = "GET_API_ACTIVITY_PLAN";
export const GET_API_DASHBOARD_MESSAGES = "GET_API_DASHBOARD_MESSAGES";
export const GET_API_VIDEO_MORE = "GET_API_VIDEO_MORE";
export const GET_API_DROPDOWN = "GET_API_DROPDOWN"; //1.9f
export const GET_API_CARE_TEAM_NOTES = "GET_API_CARE_TEAM_NOTES"; //1.9f
export const GET_API_CLOSE_MESSAGE = "GET_API_CLOSE_MESSAGE"; //1.9e

export const GET_ACTIVITY_LOG = "GET_ACTIVITY_LOG";
export const ADD_ACTIVITY_LOG = "ADD_ACTIVITY_LOG";
export const DELETE_ACTIVITY_LOG = "DELETE_ACTIVITY_LOG";
export const EDIT_ACTIVITY_LOG = "EDIT_ACTIVITY_LOG";
