import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { Col } from "react-bootstrap";

interface HealthAlertProps {
  main_title: any;
  main_description: any;
  second_title: any;
  second_list: any;
}
export default function HealthAlert(props: HealthAlertProps) {
  const { main_title, main_description, second_title, second_list } = props;
  const [show, setShow] = useState(true);
  return (
    <HealthAlertWrapper>
      <Alert show={show} variant={"success"} className="row">
        <Col className="alert-main" sm={9}>
          <Alert.Heading className="typography headings-small color-pure-white">
            {main_title}
          </Alert.Heading>
          <p className="color-pure-white typography m-0">{main_description}</p>
        </Col>
        <Col className="alert-secondary" sm={3}>
          <Alert.Heading className="typography headings-small color-text-primary">
            <span>{second_title}</span>
            <i
              className="fa fa-close"
              aria-hidden="true"
              onClick={() => setShow(false)}
            ></i>
          </Alert.Heading>
          {second_list.map((sub_desp: any, index: any) => (
            <div
              key={"secondary-alert-desp-" + index}
              className="second_desp color-text-secondary"
            >
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
              {sub_desp}
            </div>
          ))}
        </Col>
      </Alert>
    </HealthAlertWrapper>
  );
}

const HealthAlertWrapper = styled.div`
  .alert {
    border-radius: 12px;
    border: 0px;
    padding: 0px;
    width: 100%;
    margin: 0px;
    margin-bottom: 32px;
    display: flex;
    .alert-main {
      padding: 24px;
      background-color: #6a9874;
      border-bottom-left-radius: 12px;
      border-top-left-radius: 12px;
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-align: left;
      }
    }
    .alert-secondary {
      padding: 24px;
      background-color: #f4f0ee;
      border-bottom-right-radius: 12px;
      border-top-right-radius: 12px;
      .alert-heading {
        display: flex;
        justify-content: space-between;
        i {
          cursor: pointer;
          font-size: 20px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.02em;
          text-align: right;
          color: #1d1f1d;
        }
      }
      .second_desp {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-align: left;
        i {
          color: #18181b;
          padding-right: 4px;
        }
      }
    }
  }
`;
