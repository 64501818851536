import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Avatar from "../Avatar";
import ActivityModal from "../Modal/ActivityModal";
import { EditActivityLog, DeleteActivityLog } from "../../action/activityLog";
import { useDispatch } from "react-redux";
interface ActivityLogCardProps {
  id: number;
  title: string;
  description: string;
  dropdowns: any;
  tags: any;
}
export default function ActivityLogCard(props: ActivityLogCardProps) {
  const { id, title, description, tags, dropdowns } = props;
  const dispatch = useDispatch();
  const filteredTitle = dropdowns.filter((sub: any) => sub.cat_id == title);
  const [editFlag, setEditFlag] = useState(false);
  console.log(">>props>>", props);
  return (
    <ActivityLogCardWrapper>
      <div className="card">
        <div className="card-header bg-transparent modal-header">
          <h5 className="modal-title typography body-small-bold color-text-secondary">
            {filteredTitle.length > 0 ? filteredTitle[0].cat_name : title}
          </h5>
          <div
            onClick={() => {
              setEditFlag(true);
            }}
            style={{ marginLeft: "auto" }}
          >
            <i className="fa fa-edit" aria-hidden="true"></i>
          </div>
          <ActivityModal
            show={editFlag}
            edit={true}
            handleClose={() => {
              setEditFlag(false);
            }}
            title={title}
            id={id}
            description={description}
            tags={tags}
            dropdowns={dropdowns}
            handleSave={(obj: any) => {
              console.log("handlesave->", obj);
              EditActivityLog(
                obj.id,
                obj.title,
                obj.description,
                obj.tags
              )(dispatch);
              setEditFlag(false);
            }}
            handleRemove={(obj: any) => {
              DeleteActivityLog(
                obj.id,
                obj.title,
                obj.description,
                obj.tags
              )(dispatch);
              setEditFlag(false);
            }}
          />
        </div>
        <div className="card-body">
          <div>
            <p className="typography body-small-regular mb-0">{description}</p>
          </div>
          <div className="card-op">
            {tags.map((tag: any, index: any) => (
              <div
                key={"tag" + index}
                className="tag typography body-xsmall-regular"
              >
                {index == 0 ? tag : `${tag} minutes`}
              </div>
            ))}
          </div>
        </div>
      </div>
    </ActivityLogCardWrapper>
  );
}

const ActivityLogCardWrapper = styled.div`
  .card {
    border-radius: 4px;
    padding: 12px;
    background-color: #e6ebe7;
    border: 0px;
    margin-bottom: 8px;
    .card-header {
      div {
        cursor: pointer;
      }
    }
  }
  .card-body {
    padding: 0px;
    .card-op {
      display: flex;
      margin-top: 12px;
      div.tag {
        padding: 4px 8px;
        border-radius: 99px;
        border: 1px solid #e6ebe7;
        background-color: white;
        gap: 4px;
        margin-right: 10px;
      }
    }
  }
`;
