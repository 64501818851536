import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
interface HealthListProps {}
export default function HealthList(props: HealthListProps) {
  const item = [
    {
      imgSrc: "https://a360h.com/assets/images/list-item.png",
      title: "DASH diet can help with hypertension",
    },
    {
      imgSrc: "https://a360h.com/assets/images/list-item.png",
      title: "DASH diet can help with hypertension",
    },
    {
      imgSrc: "https://a360h.com/assets/images/list-item.png",
      title: "DASH diet can help with hypertension",
    },
    {
      imgSrc: "https://a360h.com/assets/images/list-item.png",
      title: "DASH diet can help with hypertension",
    },
  ];
  const about = "";
  return (
    <HealthListWrapper>
      <Row>
        <Col sm={8}>
          <div className="health-list">
            <h4>Related Videos and Articles</h4>
            <div className="row">
              {item.map((subItem, index) => (
                <HealthListItem
                  key={"HealthList-" + index}
                  className="col-md-3 col-sm-3 col-3"
                >
                  <img src={subItem.imgSrc} />
                  <p className="typography color-text-primary body-xsmall-regular">
                    {subItem.title}
                  </p>
                </HealthListItem>
              ))}
            </div>
          </div>
        </Col>
        <Col sm={4} className="health-list">
          <h4 className="color-text-primary">About SBP</h4>
          <p className="typography desktop-body-small color-text-secondary m-0">
            Blood pressure is measured using two numbers: The first number,
            called systolic blood pressure, measures the pressure in your
            arteries when your heart beats. The second number, called diastolic
            blood pressure, measures the pressure in your arteries when your
            heart rests between beats.
          </p>
        </Col>
      </Row>
    </HealthListWrapper>
  );
}

const HealthListWrapper = styled.div`
  width: 100%;
`;
const HealthListItem = styled.div`
  max-width: 200px;
  padding-right: 16px;
  img {
    width: 100%;
  }
  h4 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 16px;
  }
  .health-list {
    background-color: #f4f4f4;
    padding: 24px;
    gap: 16px;
    border-radius: 12px;
  }
  @media (max-width: 770px) {
    max-width: 149px;
  }
`;
