import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import Modal from "react-bootstrap/Modal";

interface ActivityModalProps {
  edit?: boolean;
  show: boolean;
  title?: any;
  description?: any;
  tags?: any;
  id?: number;
  dropdowns?: any;
  handleClose: () => void;
  handleRemove?: (obj: Object) => void;
  handleSave: (obj: Object) => void;
}
export default function ActivityModal(props: ActivityModalProps) {
  const {
    show,
    edit = false,
    handleClose,
    handleSave,
    handleRemove,
    title,
    description,
    id,
    dropdowns,
    tags,
  } = props;
  const [activity, setActivity] = useState(title ? title : "");
  const [date, setDate] = useState(tags ? new Date(tags[0]) : new Date());
  const [duration, setDuration] = useState(tags ? tags[1].split(" ")[0] : 0);
  const [notes, setNotes] = useState(description ? description : "");
  const [selectedActivity, setSelectedActivity] = useState("");

  return (
    <ActivityModalWrapper className="activity-modal">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="activity_titles">
            {!edit ? "New" : "Edit"} Activity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
              <Form.Label>Activity</Form.Label>
              <Form.Select
                aria-label="Activity"
                value={selectedActivity}
                onChange={(e) => {
                  setSelectedActivity(e.target.value);
                }}
              >
                <option value="">Select Activity</option>
                {dropdowns &&
                  dropdowns.map((subDrop: any, subIndex: any) => (
                    <option key={subIndex} value={subDrop.cat_id}>
                      {subDrop.cat_name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Row>
              <Col>
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  // onChange={(e) => setDate(e.target.value)}
                  onChange={(e: any) => setDate(new Date(e.target.value))}
                  value={date.toISOString().slice(0, 10)}
                />
              </Col>
              <Col>
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="In minues"
                  onChange={(e) => setDuration(e.target.value)}
                  value={duration}
                />
              </Col>
            </Row>
            <Form.Group
              className="mb-1"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Text area"
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button
                variant="button btn-small btnn-primary"
                className="button btnn-primary save-activity"
                onClick={() => {
                  handleSave({
                    title: selectedActivity,
                    description: notes,
                    tags: [date.toISOString().slice(0, 10), `${duration}`],
                  });
                }}
              >
                Save Activity
              </Button>
            </Col>
            {edit && (
              <Col>
                <Button
                  variant="outline-danger button btn-small"
                  onClick={() => {
                    if (handleRemove)
                      handleRemove({
                        id: 2,
                        title: activity,
                        description: notes,
                        tags: [date.toISOString().slice(0, 10), `${duration}`],
                      });
                  }}
                >
                  Delete Activity
                </Button>
              </Col>
            )}
          </Row>
        </Modal.Footer>
      </Modal>
    </ActivityModalWrapper>
  );
}

const ActivityModalWrapper = styled.div``;
