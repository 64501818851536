import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// import { HashRouter as Router, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import HealthMetrics from "./pages/HealthMetrics";
import { HashRouter as Router, Route } from "react-router-dom";

// const MainRoute = () => {
//   let routes = useRoutes([
//     { path: "/", element: <Home /> },
//     { path: "/health-metrics", element: <HealthMetrics /> },
//   ]);
//   return routes;
// };
function App() {
  return (
    <div className="App">
      <Router basename="/">
        {/* <MainRoute /> */}
        <Route path="/" exact component={Home} />
        <Route path="/health-metrics" component={HealthMetrics} />
        {/* <Route path="/" element={<Home />} />
        <Route path="/health-metrics" element={<HealthMetrics />} /> */}
      </Router>
    </div>
  );
}

export default App;
