import {
  GET_API_ACTIVITY_PLAN,
  GET_API_DASHBOARD_HEADER,
  GET_API_TARGET_OVERVIEW,
  GET_API_DASHBOARD_MESSAGES,
  GET_API_VIDEO_MORE,
  GET_API_DROPDOWN,
  GET_API_CARE_TEAM_NOTES,
  GET_API_CLOSE_MESSAGE,
} from "./type";
import { Dispatch } from "redux";

import ApiServices from "../services/apiServices";

export const receiveDashbaordHeader = () => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiServices.getDashboardHeader();
      dispatch({
        type: GET_API_DASHBOARD_HEADER,
        payload: res.data,
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const receiveTargetOverview = () => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiServices.getTargetOverview();
      dispatch({
        type: GET_API_TARGET_OVERVIEW,
        payload: res.data,
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const receiveDashboardMessages = () => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiServices.getDashboardMessages();
      dispatch({
        type: GET_API_DASHBOARD_MESSAGES,
        payload: res.data,
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const receiveVideoMore = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiServices.getVideoMore(id);
      dispatch({
        type: GET_API_VIDEO_MORE,
        payload: {
          id: id == 29 ? "Nutrition" : id == 28 ? "Behavior" : "Fitness",
          data: res.data,
        },
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};
export const receiveActivityPlan = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiServices.getActivityPlan(id);
      dispatch({
        type: GET_API_ACTIVITY_PLAN,
        payload: { id, data: res.data },
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const receiveDropdowns = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiServices.getDropdowns(id);
      dispatch({
        type: GET_API_DROPDOWN,
        payload: {
          id: id == 14 ? "Nutrition" : id == 15 ? "Behavior" : "Fitness",
          data: res.data,
        },
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const receiveCareTeamNotes = (id: number) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiServices.getCareTeamNotes(id);
      dispatch({
        type: GET_API_CARE_TEAM_NOTES,
        payload: {
          id: id == 1 ? "Nutrition" : id == 2 ? "Behavior" : "Fitness",
          data: res.data,
        },
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};

export const checkCloseMessage = (type: any, entry_id: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await ApiServices.getCloseMessage(type, entry_id);
      dispatch({
        type: GET_API_CLOSE_MESSAGE,
        payload: {
          type: type,
          id: entry_id,
          data: res.data,
        },
      });
    } catch (err) {
      console.log("err", err);
    }
  };
};
