import React, { useState, useEffect } from "react";
import styled from "styled-components";
interface ListProps {
  items: any[];
}
export default function List(props: ListProps) {
  const { items } = props;
  return (
    <ListWrapper className="row">
      {items.map((subItem, index) => (
        <ListItem key={"List-" + index} className="col-md-4 col-sm-6 col-6">
          <a
            href={"https://a360h.com/content/article/" + subItem.url_title}
            style={{ textDecoration: "none" }}
          >
            <img
              src={
                "https://a360h.com/assets/images/articles/" +
                subItem.display_image
              }
            />
            <p className="typography color-text-primary body-xsmall-regular">
              {subItem.title}
            </p>
          </a>
        </ListItem>
      ))}
    </ListWrapper>
  );
}

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const ListItem = styled.div`
  max-width: 200px;
  padding-right: 16px;
  img {
    width: 100%;
  }
  @media (max-width: 770px) {
    max-width: 149px;
  }
`;
