import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Button from "./../../components/Button";
import Navbar from "./../../components/Navbar";
import Sliders from "./../../components/Slider";
import Progressbar from "../../components/Progressbar";

interface TargetOverviewProps {
  targetOverview: any;
  ismobile: any;
}
export default function TargetOverview(props: TargetOverviewProps) {
  const { targetOverview, ismobile } = props;
  const items = [
    {
      title: targetOverview?.chart_ep_label || "",
      description: targetOverview?.chart_ep_target_description || "",
      value: targetOverview?.chart_ep_metric || 0,
      percent: (targetOverview?.chart_ep_metric / 150) * 100,
      time:
        targetOverview?.chart_ep_metric == 0
          ? "0 minute"
          : `${targetOverview?.chart_ep_metric || 0} minutes`,
    },
    {
      title: targetOverview?.chart_rd_label || "",
      description: targetOverview?.chart_rd_target_description || "",
      value: targetOverview?.chart_rd_metric || 0,
      percent: (targetOverview?.chart_rd_metric / 30) * 100,
      time:
        targetOverview?.chart_rd_metric == 0
          ? "0 gram"
          : `${targetOverview?.chart_rd_metric || 0} grams`,
    },
    {
      title: targetOverview?.chart_hp_label || "",
      description: targetOverview?.chart_hp_target_description || "",
      value: targetOverview?.chart_hp_metric || 0,
      percent: (targetOverview?.chart_hp_metric / 3) * 100,
      time:
        targetOverview?.chart_hp_metric == 0
          ? "0 time"
          : `${targetOverview?.chart_hp_metric || 0} times`,
    },
  ];
  return (
    <TargetOverviewWrapper className="container">
      <p className="typography body-medium-regular"> Target Overview</p>
      {!ismobile ? (
        <div className="target-overview-cards row">
          {items.map((item, index) => (
            <TargetOverviewCard
              key={"target-overview-" + index}
              className="col-md-4"
            >
              <div className="target-overview-card">
                <div className="header-title">
                  <h3 className="color-text-primary typography nav-label p-0 m-0">
                    {item.title}
                  </h3>
                  <p className="color-text-secondary typography nav-label p-0 m-0">
                    {item.time}
                  </p>
                </div>
                <Progressbar value={item.percent} />
                <p className="target-overview-description color-text-secondary typography nav-label p-0 m-0">
                  {item.description}
                </p>
              </div>
            </TargetOverviewCard>
          ))}
        </div>
      ) : (
        <TargetOverviewCard className="row target-overview-card">
          {items.map((item, index) => (
            <div className=" col-4">
              <div className="header-title">
                <h3 className="color-text-primary typography nav-label p-0 m-0">
                  {item.title}
                  <p className="color-text-secondary typography">{item.time}</p>
                </h3>
              </div>
              <Progressbar value={item.percent} />
            </div>
          ))}
        </TargetOverviewCard>
      )}
    </TargetOverviewWrapper>
  );
}

const TargetOverviewWrapper = styled.div`
  padding-top: 40px;
  .target-overview-card {
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0px 2px 8px 0px #0000000d;
    background: white;
    margin-bottom: 32px;
    .header-title {
      display: flex;
      justify-content: space-between;
      h3.typography.nav-label {
        font-weight: 500;
      }
      p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }
    p.target-overview-description {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;
const TargetOverviewCard = styled.div`
  padding-right: 16px;
  &.target-overview-card.row {
    margin-left: 0px;
    margin-right: 0px;
    padding: 16px 12px;
    .header-title {
      h3 {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
      }
    }
    .col-4 {
      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
      p.target-overview-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }
  }
`;
