import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Accordians from "./../../components/Accordian";
import "zingchart/es6";
// @ts-ignore
import ZingChart from "zingchart-react";
// EXPLICITLY IMPORT MODULE
import "zingchart/modules-es6/zingchart-depth.min.js";
import Button from "react-bootstrap/Button";
import ActivityLogCard from "../../components/Card/ActivityLogCard";
import ActivityModal from "../../components/Modal/ActivityModal";
import { useDispatch, useSelector } from "react-redux";
import { receiveActivityLog, AddActivityLog } from "../../action/activityLog";
import Avatar from "./../../components/Avatar";
import List from "../../components/List";

interface TargetCardProps {
  charts: any;
  item: any;
  index: any;
  ismobile: any;
  videosMore: any;
  dropdowns: any;
  careTeamNotes: any;
}
export default function TargetCard(props: TargetCardProps) {
  const {
    item,
    charts,
    index,
    ismobile,
    videosMore,
    dropdowns,
    careTeamNotes,
  } = props;
  const [editActivityLog, setEditActivityLog] = useState(false);
  const [newActivityFlag, setNewActivityFlag] = useState(false);
  const activityLogs = useSelector(
    (activityLog: any) => activityLog.activityLogReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    for (let i = 0; i < 3; i++) receiveActivityLog(i + 1)(dispatch);
  }, [dispatch]);
  const differenceDate = (dateStr: string) => {
    var currentDate: any = new Date();
    var targetDate: any = new Date(dateStr);
    var timeDifference = currentDate - targetDate;
    var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference === 1) {
      return "1 day ago";
    } else {
      return daysDifference + " days ago";
    }
  };
  console.log("careTeamNotes", careTeamNotes);
  return (
    <TargetCardCard ismobile={ismobile}>
      <div className="header-title">
        <div>
          <h3
            className={`color-text-primary typography ${
              !ismobile ? " headings-xlarge" : "headings-large"
            } `}
          >
            {item.head_title}
            {/* Exercise Target */}
          </h3>
          <p
            className={`color-text-secondary typography ${
              !ismobile ? "body-medium-regular" : "body-small-regular"
            }`}
          >
            {item.head_description} &nbsp;
            {/* Heart Rate greater than 150bpm for 120 minutes{" "} */}
            {/* <span className="time-box">{item.period}</span> */}
          </p>
        </div>
        <div>
          <img src={item.iconSrc} />
        </div>
      </div>
      <hr />
      <div className="target-detail-body">
        <div className="target-detail-left">
          <h4 className="typography headings-large color-text-primary">
            Activity Plan
          </h4>
          {/* <NoteSlider slides={careTeamNotes[item.head_title.split(" ")[0]]} /> */}
          {careTeamNotes[item.head_title.split(" ")[0]] && (
            <>
              <h3 className="typography headings-small color-text-primary m-0 mb-2 mt-2">
                Care Team Notes
              </h3>
              <div className="card-gray typography headings-xsmall">
                {careTeamNotes[item.head_title.split(" ")[0]].length > 0 &&
                  careTeamNotes[item.head_title.split(" ")[0]].map(
                    (subNote: any, subNoteIndex: any) => (
                      <div key={"subNote-" + subNoteIndex}>
                        {subNoteIndex > 0 && <hr />}
                        <div className="card-header">
                          <Avatar imgSrc={subNote.author_image} />
                          <div>
                            {subNote.screen_name}
                            <div
                              style={{
                                color: " #7e7e7e",
                                fontWeight: 600,
                                fontSize: "12px",
                                lineHeight: "12px",
                                margin: 0,
                                marginBottom: "5px",
                              }}
                            >
                              {differenceDate(subNote.created)}
                            </div>
                          </div>
                        </div>
                        <p className="typography color-text-primary mobile-body-small mb-0">
                          {subNote.message}
                        </p>
                      </div>
                    )
                  )}
                <div className="button-group-accordian">
                  <a
                    href="https://a360h.com/messages"
                    style={{ textDecoration: "none" }}
                  >
                    <Button variant="outline-secondary button btn-small btnn-secondary ">
                      Go to Inbox
                    </Button>
                  </a>
                </div>
              </div>
            </>
          )}
          {videosMore[item.head_title.split(" ")[0]] && (
            <>
              <h3 className="typography headings-small color-text-primary m-0 mb-2 mt-4">
                Videos and More
              </h3>
              <div className="video-more-list">
                <List items={videosMore[item.head_title.split(" ")[0]] || []} />
              </div>{" "}
            </>
          )}
          {item.activity_plan && <Accordians accordian={item.activity_plan} />}
        </div>
        <div className="target-detail-right">
          <ZingChart
            data={charts[index]}
            width={"100%"}
            height={ismobile ? 300 : 400}
            hideprogresslogo={false}
            cache={{ data: true, css: false }}
          ></ZingChart>
          <div className="zingchart-descp">
            {item.edit_activity_log && !editActivityLog && (
              <Button
                variant="button btn-small btnn-primary"
                className="button btnn-primary"
                onClick={() => {
                  setEditActivityLog(true);
                }}
              >
                <i className="fa fa-list-ul" aria-hidden="true"></i> Edit
                Activity Log
              </Button>
            )}
            {item.edit_activity_log && editActivityLog && (
              <div className="activity_log">
                <div className="activity_log_title">
                  <h4 className="color-text-primary">Activity Log</h4>
                  <div onClick={() => setEditActivityLog(false)}>
                    <i className="fa fa-close" aria-hidden="true"></i>
                  </div>
                </div>
                <div className="activity_log_body">
                  {activityLogs &&
                    activityLogs[item.head_title.split(" ")[0]].map(
                      (editSub: any, editIndex: any) =>
                        editSub.disable ? (
                          <div key={"EditSub-" + editIndex}></div>
                        ) : (
                          <ActivityLogCard
                            key={"EditSub-" + editIndex}
                            title={editSub.title}
                            dropdowns={dropdowns[item.head_title.split(" ")[0]]}
                            description={editSub.note}
                            tags={[editSub.date, editSub.duration]}
                            id={editSub.entry_id}
                          />
                        )
                    )}
                </div>
                <div className="activity_log_action">
                  <Button
                    variant="button btn-small btnn-primary"
                    className="button btnn-primary"
                    onClick={() => {
                      setNewActivityFlag(true);
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i> New
                    Activity
                  </Button>
                  <Button
                    variant="outline-secondary button btn-small btnn-secondary"
                    onClick={() => {
                      setEditActivityLog(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <ActivityModal
                  show={newActivityFlag}
                  dropdowns={dropdowns[item.head_title.split(" ")[0]]}
                  handleClose={() => {
                    setNewActivityFlag(false);
                  }}
                  handleSave={(obj: any) => {
                    AddActivityLog(
                      2,
                      obj.title,
                      obj.description,
                      obj.tags
                    )(dispatch);
                    setNewActivityFlag(false);
                  }}
                />
              </div>
            )}
            <h4
              className={`color-text-primary px-3 py-0  m-0 typography ${
                ismobile && " body-small-regular p-0"
              }`}
            >
              {item.head_description}
            </h4>
            <p
              className={`color-text-secondary  ${
                ismobile ? " px-0" : " px-3"
              }`}
            >
              {item.updated_date}
            </p>
          </div>
        </div>
      </div>
    </TargetCardCard>
  );
}

const TargetCardCard = styled.div<{ ismobile: boolean }>`
  border-radius: 8px;
  ${({ ismobile }) => (ismobile ? `padding: 12px;` : `padding: 24px;`)};
  box-shadow: 0px 2px 8px 0px #0000000d;
  background: white;
  margin-bottom: 44px;
  gap: 16px;
  .header-title {
    display: flex;
    justify-content: space-between;
    .time-box {
      border: 1px solid #f4f4f4;
      padding: 4px 8px 4px 8px;
      color: #1d1f1d;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      letter-spacing: -2%;
    }
    img {
      ${({ ismobile }) => (!ismobile ? `width: 56px;` : `width: 44px;`)};
    }
  }
  .target-detail-body {
    display: flex;
    flex-wrap: wrap-reverse;

    .target-detail-left {
      width: 60%;
      .card-gray {
        .card-header {
          line-height: 33px;
          div {
            margin-right: 8px;
          }
          margin-bottom: 8px;
        }
        background: #e6ebe745;
        padding: 16px;
        border-radius: 12px;
        gap: 8px;
        .card-header {
          display: flex;
        }
        .button-group-accordian {
          margin-bottom: 0px;
          display: flex;
          a {
            width: 110px;
            margin-left: auto;
          }
        }
      }
    }
    .target-detail-right {
      width: 40%;
      [id^="zingchart-react-"] {
        width: 100% !important;
        height: 450px !important;
      }
      h4 {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        padding: 20px 0px;
        &.body-small-regular {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.28px;
          padding: 0px !important;
        }
      }
      p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .target-detail-right {
      position: relative;
      overflow: auto;
      padding-left: 20px;
      .zingchart-descp {
        position: absolute;
        top: 250px;
        width: 100%;
        button {
          width: calc(100% - 34px);
          margin: 0px 17px;
          border-radius: 4px;
          margin-bottom: 10px;
        }
        .activity_log {
          padding: 0px 20px;
          margin-bottom: 10px;
          .activity_log_title {
            display: flex;
            justify-content: space-between;
            & > div {
              cursor: pointer;
            }
            h4 {
              font-family: Poppins;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: -0.02em;
              text-align: left;
              padding: 0px;
              margin: 0px;
            }
            margin-bottom: 12px;
          }
          .activity_log_body {
          }
          .activity_log_action {
            display: flex;
            justify-content: space-between;
            button {
              width: calc(50% - 5px);
              margin: 0px;
            }
          }
        }
      }
    }
    @media (max-width: 770px) {
      .target-detail-left {
        width: 100%;
        margin-top: 30px;
      }
      .target-detail-right {
        width: 100%;
        [id^="zingchart-react-"] {
          width: 100% !important;
          height: 290px !important;
        }
        .zingchart-descp {
          position: sticky;
          margin-top: -100px;
        }
      }
    }
    .video-more-list {
      background: #e6ebe745;
      padding: 10px 8px;
      border-radius: 12px;
      gap: 8px;
      & > div.row {
        margin: 0px;
        .col-md-4.col-6 {
          padding: 6px 8px;
          img {
            border-radius: 4px;
            margin-bottom: 4px;
          }
          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`;
