import http from "../config/http-common";
const XID = "2edc9486569c9707c50205606246b206b9ce907d";
const getPIDPKEY = () => {
  let PIDelement: any = document.getElementById("PID");
  let PKEYelement: any = document.getElementById("PKEY");
  const PID = PIDelement ? PIDelement.value : "MzQyMDk=";
  const PKEY = PKEYelement ? PKEYelement.value : "NURBNzVX";
  return `${PID}/${PKEY}`;
};
const getWrapper = () => {
  return http.get(`/transact/wrapper/${getPIDPKEY()}`);
};
const getDashboardHeader = () => {
  return http.get(`/transact/dashboard-header/${getPIDPKEY()}`);
};
const getTargetOverview = () => {
  return http.get(`/transact/target-summary/${getPIDPKEY()}`);
};
const getDashboardMessages = () => {
  return http.get(`/transact/dashboard-messages/${getPIDPKEY()}`);
};
const getActivityPlan = (id: number) => {
  return http.get(`/transact/activity-plan/${id}/${getPIDPKEY()}`);
};
const getVideoMore = (id: number) => {
  return http.get(`/transact/related-content/${id}/${getPIDPKEY()}`);
};
const getDropdowns = (id: number) => {
  return http.get(`/transact/dropdown-items/${id}/${getPIDPKEY()}`);
};
const getCareTeamNotes = (id: number) => {
  return http.get(`/transact/care-team-notes/${id}/${getPIDPKEY()}`);
};

const getCloseMessage = (type: number, entry_id: number) => {
  return http.get(`/transact/close-alert/${type}/${entry_id}/${getPIDPKEY()}`);
};

const addActivityLog = (id: number, data: any) => {
  console.log("...acivitylog send", id, data);
  return http.post(`/transact/activity-add/${id}/${getPIDPKEY()}`, {
    XID: XID,
    ...data,
  });
};
const getActivityLogs = (id: number) => {
  return http.get(`/transact/activity-items/${id}/MzQyMDk=/NURBNzVX`);
};
// const get = (id) => {
//   return http.get(`/tutorials/${id}`);
// };

// const create = (data) => {
//   return http.post("/tutorials", data);
// };

// const update = (id, data) => {
//   return http.put(`/tutorials/${id}`, data);
// };

// const remove = (id) => {
//   return http.delete(`/tutorials/${id}`);
// };

// const removeAll = () => {
//   return http.delete(`/tutorials`);
// };

// const findByTitle = (title) => {
//   return http.get(`/tutorials?title=${title}`);
// };

const ApiServices = {
  getWrapper,
  getDashboardHeader,
  getTargetOverview,
  getActivityPlan,
  getDashboardMessages,
  getVideoMore,
  getDropdowns,
  getCareTeamNotes,
  getCloseMessage,
  addActivityLog,
  getActivityLogs,
};

export default ApiServices;
