import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface ProgressbarProps {
  value: number;
}
export default function Progressbar(props: ProgressbarProps) {
  const { value } = props;
  return (
    <ProgressbarWrapper>
      <div className="progress-stacked-line">
        <div style={{ left: `${value}%` }}></div>
      </div>
      <div className="progress-stacked">
        <div className="progress" role="progressbar" style={{ width: "33%" }}>
          <div
            className={
              "progress-bar " + `${value < 34 && "active"}` + " bg-warning1"
            }
          ></div>
        </div>
        <div className="progress" role="progressbar" style={{ width: "0.5%" }}>
          <div className="progress-bar bg-light"></div>
        </div>
        <div className="progress" role="progressbar" style={{ width: "33%" }}>
          <div
            className={
              "progress-bar " +
              `${value <= 66 && value >= 34 && "active"}` +
              " bg-success1"
            }
          ></div>
        </div>
        <div className="progress" role="progressbar" style={{ width: "0.5%" }}>
          <div className="progress-bar bg-light"></div>
        </div>
        <div className="progress" role="progressbar" style={{ width: "33%" }}>
          <div
            className={
              "progress-bar " + `${value > 66 && "active"}` + " bg-warning1"
            }
          ></div>
        </div>
      </div>
    </ProgressbarWrapper>
  );
}

const ProgressbarWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 12px;
  position: relative;
  .progress-stacked-line {
    position: absolute;
    z-index: 1001;
    width: 100%;
    border-radius: 0px;
    height: 14px;
    div {
      position: relative;
      width: 4px;
      height: 100%;
      background: #1d1f1d;
      top: -3px;
      border-radius: 99px;
    }
  }
  .progress-stacked {
    position: relative;
    height: 8px;
    z-index: 1000;
    .progress-bar {
      &.bg-success1 {
        background-color: rgb(201, 214, 200);
      }
      &.bg-warning1 {
        background-color: rgb(243, 237, 187);
      }
      &.bg-success1.active {
        border: 1px solid #6a9874;
        box-shadow: 0px 0px 2px 0px #6a9874;
        background-color: #6a9874;
      }
      &.bg-warning1.active {
        border: 1px solid #dbcc5c;
        box-shadow: 0px 0px 4px 0px #dbcc5c;
        background-color: #dbcc5c;
      }
    }
  }
  .progress-stacked.initial-progress {
    background: transparent;
    height: 14px;
    position: absolute;
    display: relative;
  }
`;
