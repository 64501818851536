import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "zingchart/es6";
// @ts-ignore
import ZingChart from "zingchart-react";
// EXPLICITLY IMPORT MODULE

interface AreaChartProps {}
export default function AreaChart(props: AreaChartProps) {
  const myData = [
    {
      type: "area",
      plot: {
        "active-area": true,
      },
      "scale-x": {
        values: "1:27:0",
        zooming: 1,
        "line-width": "2px",
        "line-color": "#A1A8A2",
        tick: {
          "line-width": "0px",
        },
        item: {
          "font-family:": "Inter",
          "font-size": "12px",
          "font-weight": "400",
          "line-height": "8px",
        },
      },
      "scale-y": {
        values: "0:100:50",
        zooming: 1,
        guide: {
          "line-width": "1px",
          "line-color": "#A1A8A2",
          alpha: 1,
          "line-style": "dashed",
        },
        tick: {
          "line-width": "0px",
        },
        "line-width": "0px",
        item: {
          "font-family:": "Inter",
          "font-size": "12px",
          "font-weight": "400",
          "line-height": "8px",
        },
        labels: ["", 50, 100],
      },
      series: [
        {
          values: [
            80, 90, 40, 75, 30, 20, 15, 10, 40, 90, 70, 60, 95, 55, 50, 35, 30,
            45, 20, 20, 20, 60, 55, 20, 20, 10, 60,
          ],
          marker: {
            "background-color": "#6A9874" /* hexadecimal or RGB value */,
            size: 1 /* in pixels */,
            "border-color": "#6A9874" /* hexadecimal or RBG value */,
            "border-width": 1,
          },
          // "background-color": "#6a987499 #6a98741a",
          "line-color": "#6A9874",
          "background-color":
            "rgba(106, 152, 116, 0.1) rgba(106, 152, 116, 0.6)",
          "alpha-area": 0.5 /* Shaded region transparency */,
        },
      ],
    },
  ];
  return (
    <AreaChartWrapper>
      <ZingChart
        data={myData[0]}
        width={"100%"}
        hideprogresslogo={false}
        cache={{ data: true, css: false }}
      ></ZingChart>
    </AreaChartWrapper>
  );
}

const AreaChartWrapper = styled.div``;
