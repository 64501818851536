import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Navbar from "./../../components/Navbar";
import HealthAlert from "../../components/Alert";
import HealthAccordian from "../../components/Accordian/HealthAccordian";

export default function HealthMetrics() {
  const item_accordian: any = [
    {
      title: "Added by Care Team",
      unitInfo: [
        {
          title: "Glucose",
          amount: 67,
          unit: "mg/DL",
          lastUpdated: "Auguest 9, 2023",
          avatar: "https://a360h.com/assets/images/Avatar.png",
          name: "Dr.Fernandez",
        },
        {
          title: "LDL",
          amount: 67,
          unit: "ml",
          lastUpdated: "Auguest 9, 2023",
          avatar: "https://a360h.com/assets/images/Avatar.png",
          name: "Dr.Fernandez",
        },
        {
          title: "SBP",
          amount: 67,
          unit: "mmHG",
          lastUpdated: "Auguest 9, 2023",
          avatar: "https://a360h.com/assets/images/Avatar.png",
          name: "Dr.Fernandez",
        },
        {
          title: "Fiber",
          amount: 32,
          unit: "grams",
          lastUpdated: "Auguest 9, 2023",
          avatar: "https://a360h.com/assets/images/Avatar.png",
          name: "Dr.Fernandez",
        },
      ],
    },
  ];
  return (
    <HealthMetricsWrapper>
      <Navbar />
      <div className="health-metrics-body container">
        <h1 className="color-text-primary">Health Metrics</h1>
        <HealthAlert
          main_title={"What is this?"}
          main_description={
            "Within this section, you can explore all of your health information that’s relevant for your health journey. These analytics are monitored in real time and with your doctor’s help can provide you with more ways to reach your health goals."
          }
          second_title={"LearnMore"}
          second_list={["Supported Devices", "All Metrics"]}
        />
        <HealthAccordian accordian={item_accordian} />
        {/* <AddedCareWrapper>
          <h2>Added by Care Team</h2>
          
        </AddedCareWrapper> */}
      </div>
    </HealthMetricsWrapper>
  );
}

const HealthMetricsWrapper = styled.div`
  .health-metrics-body {
    h1 {
      font-family: Poppins;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -0.02em;
      text-align: left;
      margin-top: 48px;
    }
  }
`;
const AddedCareWrapper = styled.div`
  padding: 24px;
  box-shadow: 0px 2px 8px 0px #0000000d;
  background-color: white;
  border-radius: 12px;
  border: 0px;
  h2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 16px;
  }
`;
