import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Logo from "./../../assets/apollo_logo.png";
import Avatar from "./../Avatar";
interface NavbarProps {
  // header: any;
}
export default function Navbar(props: NavbarProps) {
  // const { header } = props;
  return (
    <NavbarWrapper className="navbar-light bg-light">
      <nav className="navbar navbar-expand-lg navbar-light bg-light container">
        <div className="container-fluid p-0">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="#">
            <img src={Logo} alt="" />
          </a>
          <div className="collapse navbar-collapse " id="navbarTogglerDemo03">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              <li className="nav-item">
                <a
                  className="nav-link active color-text-secondary"
                  aria-current="page"
                  href="#"
                >
                  Your Goal
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link color-text-secondary" href="#">
                  Health Metrics
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link color-text-secondary" href="#">
                  Learn
                </a>
              </li>
            </ul>
            <div className="d-flex">
              <button
                className="btn btnn-primary position-relative"
                type="submit"
              >
                <i className="fa fa-bell-o color-primary" />
                <span className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle">
                  <span className="visually-hidden">New alerts</span>
                </span>
              </button>
              <Avatar imgSrc={"Avatar.png"} />
            </div>
          </div>
        </div>
      </nav>
    </NavbarWrapper>
  );
}

const NavbarWrapper = styled.div`
  div.collapse.navbar-collapse {
    ul.navbar-nav.me-auto {
      margin-left: auto;
      margin-right: auto;
      li.nav-item a.nav-link {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.02em;
        text-align: left;
      }
    }
    button {
      i {
        font-size: 18px;
        line-height: 20px;
      }
      span {
        width: 8px;
        height: 8px;
        top: 14px !important;
        left: 27px !important;
        border: 2px solid #ffffff !important;
        background-color: #f57a3d !important;
        padding: 3px !important;
      }
    }
  }
`;
